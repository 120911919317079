import { PlusOutlined } from '@ant-design/icons';
import {
  Col,
  ColorPicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
} from 'antd';
import { cloneDeep, map } from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  ACCENT_COLORS,
  CONFIG_TYPE,
  EMAIL_COMPONENT_VARIENTS,
  EMAIL_OPTIONS,
  EMPTY_STATE_IMAGES_KEYS,
  FONT_FAMILY,
  HEADER_COMPONENT_VARIENTS,
  NUMBER_FIELD_TYPES,
  PRIMARY_COLORS,
  SORT_OPTIONS,
  VISIBILITY_OPTIONS,
} from '@common/constants';
import {
  colorAdjusting,
  convertColor,
  getBase64,
  validateImage,
} from '@common/utils';

import CommonPreview from '../../../components/CommonPreview';
import NumberComponent from '../../../components/NumberComponent';
import SelectComponent from '../../../components/SelectComponent';

const OrganizationConfigForm = ({
  form,
  onFinish,
  handleShowPrompt,
  isView,
  organizationConfigData,
  themeConfig,
  setThemeConfig,
  buttonsConfig,
  setButtonsConfig,
  layoutConfig,
  setLayoutConfig,
  headerComponentVariantConfig,
  setHeaderComponentVariantConfig,
  bookSurveyConfig,
  setBookSurveyConfig,
  waitListConfig,
  setWaitListConfig,
  visibilityConfig,
  setVisibilityConfig,
  selectedFont,
  setSelectedFont,
  selectedEmailPlace,
  setSelectedEmailPlace,
  trackingId,
  setTrackingId,
  emptyImagesState,
  setEmptyImagesState,
  selectedDefaultSort,
  setSelectedDefaultSort,
  tooltipIconConfig,
  setTootipIconCofig,
  suggestedUpgradeTextCenterAlign,
  setSuggestedUpgradeTextCenterAlign,
  selectedSecondaryFont,
  setSelectedSecondaryFont,
  setResetData,
  resetData,
  evStepVisibility,
  setEvStepVisibility,
  planIconConfig,
  setPlanIconCofig,
  emailCapturePic,
  setEmailCapturePic,
  headerPic,
  setHeaderPic,
}) => {
  const headerComponentVariants = form.getFieldValue('headerComponentVariants');
  const emailCaptureComponentVariants = form.getFieldValue(
    'emailCaptureComponentVariants',
  );
  const [colorFormat, setColorFormat] = useState('hex');
  const [visible, setVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const handleColorPick = (value, index, colorIndex) => {
    const latestThemeConfig = cloneDeep(themeConfig);
    if (index === 0 && colorIndex === 0) {
      latestThemeConfig[index].colors[1].value = colorAdjusting(
        value?.toHexString(),
        PRIMARY_COLORS?.primaryTint1,
      );
      latestThemeConfig[index].colors[2].value = colorAdjusting(
        value?.toHexString(),
        PRIMARY_COLORS?.primaryTint2,
      );
      latestThemeConfig[index].colors[3].value = colorAdjusting(
        value?.toHexString(),
        PRIMARY_COLORS?.primaryShade1,
      );
      latestThemeConfig[index].colors[4].value = colorAdjusting(
        value?.toHexString(),
        PRIMARY_COLORS?.primaryShade2,
      );
    }

    if (index === 1 && colorIndex === 0) {
      latestThemeConfig[index].colors[1].value = colorAdjusting(
        value?.toHexString(),
        ACCENT_COLORS?.accentTint1,
      );
      latestThemeConfig[index].colors[2].value = colorAdjusting(
        value?.toHexString(),
        ACCENT_COLORS?.accentTint2,
      );
      latestThemeConfig[index].colors[3].value = colorAdjusting(
        value?.toHexString(),
        ACCENT_COLORS?.accentShade1,
      );
      latestThemeConfig[index].colors[4].value = colorAdjusting(
        value?.toHexString(),
        ACCENT_COLORS?.accentShade2,
      );
    }
    latestThemeConfig[index].colors[colorIndex].value = value?.toHexString();
    setThemeConfig([...latestThemeConfig]);
  };

  const handleBtnConfig = (value, index, configIndex, type, isOtherColor) => {
    const latestBtnConfig = buttonsConfig;
    if (type === CONFIG_TYPE?.COLOR) {
      if (isOtherColor) {
        latestBtnConfig[index].configs[configIndex].value = convertColor(
          value,
          colorFormat,
        );
      } else {
        latestBtnConfig[index].configs[
          configIndex
        ].value = value?.toHexString();
      }
    } else {
      latestBtnConfig[index].configs[configIndex].value = value;
    }
    setButtonsConfig([...latestBtnConfig]);
  };

  const handleLayoutConfig = (
    value,
    index,
    configIndex,
    type,
    isOtherColor,
  ) => {
    const latestLayoutConfig = layoutConfig;
    if (type === CONFIG_TYPE?.COLOR) {
      if (isOtherColor) {
        latestLayoutConfig[index].configs[configIndex].value = convertColor(
          value,
          colorFormat,
        );
      } else {
        latestLayoutConfig[index].configs[
          configIndex
        ].value = value?.toHexString();
      }
    } else {
      latestLayoutConfig[index].configs[configIndex].value = value;
    }
    setLayoutConfig([...latestLayoutConfig]);
  };

  const handleBookSurveyConfig = (value, index, configIndex, type) => {
    const latestBookSurveyConfig = bookSurveyConfig;
    if (type === CONFIG_TYPE?.COLOR) {
      latestBookSurveyConfig[index].configs[
        configIndex
      ].value = value?.toHexString();
    } else {
      latestBookSurveyConfig[index].configs[configIndex].value = value;
    }
    setBookSurveyConfig([...latestBookSurveyConfig]);
  };

  const handleWaitListConfig = (value, index, configIndex, type) => {
    const latestBtnConfig = waitListConfig;
    if (type === CONFIG_TYPE?.COLOR) {
      latestBtnConfig[index].configs[configIndex].value = value?.toHexString();
    } else {
      latestBtnConfig[index].configs[configIndex].value = value;
    }
    setWaitListConfig([...latestBtnConfig]);
  };

  const handleVisibilityConfig = (value, index, configIndex) => {
    const latestBtnConfig = visibilityConfig;
    latestBtnConfig[index].configs[configIndex].value = value;
    setVisibilityConfig([...latestBtnConfig]);
  };

  const handleUpdateTooltipIconConfig = (value, index, configIndex) => {
    const latestBtnConfig = tooltipIconConfig;
    latestBtnConfig[index].configs[configIndex].value = value;
    setTootipIconCofig([...latestBtnConfig]);
  };

  const handleUpdateaddToPlanIconConfig = (value, index, configIndex) => {
    const latestBtnConfig = planIconConfig;
    latestBtnConfig[index].configs[configIndex].value = value;
    setPlanIconCofig([...latestBtnConfig]);
  };

  const handleVariantHeaderConfig = (value, index, colorIndex) => {
    const latestHeaderConfig = cloneDeep(headerComponentVariantConfig);
    latestHeaderConfig[index].config[colorIndex].value = value?.toHexString();
    setHeaderComponentVariantConfig([...latestHeaderConfig]);
  };

  useEffect(() => {
    if (organizationConfigData) {
      const {
        fonts,
        formSteps,
        themeColors,
        waitList,
        buttons,
        layout,
        analytics,
        updateEpcCta,
        bookSurvey,
        recommendationsDefaultSort,
        tooltip,
        addToPlan,
        alignment,
        formCustomization,
        emailCaptureComponent,
        headerComponent,
      } = organizationConfigData;
      if (emailCaptureComponent) {
        if (emailCaptureComponent?.backgroundImage) {
          setEmailCapturePic([{ url: emailCaptureComponent?.backgroundImage }]);
        }
        form?.setFieldValue(
          'emailCaptureComponentVariants',
          emailCaptureComponent?.variant,
        );
      }
      if (headerComponent) {
        if (headerComponent?.backgroundImage) {
          setHeaderPic([{ url: headerComponent?.backgroundImage }]);
        }
        form?.setFieldValue(
          'headerComponentVariants',
          headerComponent?.variant,
        );
        if (headerComponent?.config) {
          const config = headerComponentVariantConfig.map((variant) => ({
            ...variant,
            config: variant.config.map((color) => ({
              ...color,
              value: headerComponent.config[color.name] || color.value,
            })),
          }));
          setHeaderComponentVariantConfig([...config]);
        }
      }
      if (fonts) {
        setSelectedFont(fonts?.fontFamily);
        setSelectedSecondaryFont(fonts?.secondaryFontFamily);
      }
      if (formSteps?.length > 0) {
        setSelectedEmailPlace(formSteps?.[0]?.order);
      }
      if (formCustomization?.length > 0) {
        setEvStepVisibility(formCustomization?.[0]?.visibility);
      }
      if (themeColors) {
        const config = map(themeConfig, (item) => ({
          ...item,
          colors: map(item?.colors, (color) => ({
            ...color,
            value: themeColors[color?.name] || color?.value,
          })),
        }));
        setThemeConfig([...config]);
      }
      if (buttons) {
        const config = map(buttonsConfig, (item) => ({
          ...item,
          configs: map(item?.configs, (configItem) => ({
            ...configItem,
            value: buttons[configItem?.name] || configItem?.value,
          })),
        }));
        setButtonsConfig([...config]);
      }
      if (layout) {
        const config = map(layoutConfig, (item) => ({
          ...item,
          configs: map(item?.configs, (configItem) => ({
            ...configItem,
            value: layout[configItem?.name] || configItem?.value,
          })),
        }));
        setLayoutConfig([...config]);
      }
      if (bookSurvey) {
        const config = map(bookSurveyConfig, (item) => ({
          ...item,
          configs: map(item?.configs, (configItem) => ({
            ...configItem,
            value:
              bookSurvey[configItem?.name] !== null &&
              bookSurvey[configItem?.name] !== undefined
                ? bookSurvey[configItem?.name]
                : configItem?.value,
          })),
        }));
        setBookSurveyConfig([...config]);
      }
      if (waitList) {
        const config = map(waitListConfig, (item) => ({
          ...item,
          configs: map(item?.configs, (configItem) => ({
            ...configItem,
            value: waitList[configItem?.name] || configItem?.value,
          })),
        }));
        setWaitListConfig([...config]);
      }
      if (updateEpcCta) {
        const config = map(visibilityConfig, (item) => ({
          ...item,
          configs: map(item?.configs, (configItem) => ({
            ...configItem,
            value: updateEpcCta[configItem?.name] || configItem?.value,
          })),
        }));
        setVisibilityConfig([...config]);
      }

      if (tooltip?.icon) {
        const config = map(tooltipIconConfig, (item) => ({
          ...item,
          configs: map(item?.configs, (configItem) => ({
            ...configItem,
            value: tooltip?.icon[configItem?.name] || configItem?.value,
          })),
        }));

        setTootipIconCofig([...config]);
      }

      if (addToPlan?.icons) {
        const config = map(planIconConfig, (item) => ({
          ...item,
          configs: map(item?.configs, (configItem) => ({
            ...configItem,
            value: addToPlan?.icons[configItem?.name] || configItem?.value,
          })),
        }));

        setPlanIconCofig([...config]);
      }

      if (alignment) {
        setSuggestedUpgradeTextCenterAlign(
          alignment?.suggestedUpgradeTextCenterAlign,
        );
      }

      if (analytics?.key) {
        setTrackingId(analytics?.key);
      }
      setSelectedDefaultSort(recommendationsDefaultSort);
      setResetData(false);
    }
  }, [organizationConfigData, resetData]);

  const handleVisibilityChange = (key, value) => {
    setEmptyImagesState((prevImages) =>
      prevImages?.map((image) =>
        image?.key === key ? { ...image, visibility: value } : image,
      ),
    );
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setPreviewImage(fileObj?.url || preview);
  };

  const handleChange = (info) => {
    validateImage(info, form, setEmailCapturePic, 'emailCaptureBg', true);
  };

  const handleChangeHeader = (info) => {
    validateImage(info, form, setHeaderPic, 'headerBg', true);
  };

  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={previewImage}
        setImage={setPreviewImage}
      />
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={handleShowPrompt}
        disabled={!!isView}
        className={`${isView ? 'common-view-form' : 'form'} organization-form`}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Typography.Title level={5}>Font Config</Typography.Title>
            <Row className="config-form-row" align="middle">
              <Col xs={8} sm={8} md={8} lg={9} xl={8} xxl={8}>
                <Typography.Text>Font-Family : </Typography.Text>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Select
                  value={selectedFont}
                  onChange={(value) => {
                    setSelectedFont(value);
                  }}
                  options={FONT_FAMILY}
                  placeholder="Select Font-Family"
                  disabled={!!isView}
                />
              </Col>
            </Row>
            <Row className="config-form-row" align="middle">
              <Col xs={8} sm={8} md={8} lg={9} xl={8} xxl={8}>
                <Typography.Text>Secondary Font-Family : </Typography.Text>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Select
                  value={selectedSecondaryFont}
                  onChange={(value) => {
                    setSelectedSecondaryFont(value);
                  }}
                  options={FONT_FAMILY}
                  placeholder="Select Secondary Font-Family"
                  disabled={!!isView}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Typography.Title level={5}>Email Config</Typography.Title>
            <Row className="config-form-row" align="middle">
              <Col xs={8} sm={8} md={8} lg={9} xl={8} xxl={8}>
                <Typography.Text>Capture Email At :</Typography.Text>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Select
                  value={selectedEmailPlace || 0}
                  onChange={(value) => {
                    setSelectedEmailPlace(value);
                  }}
                  options={EMAIL_OPTIONS}
                  placeholder="Select Email Option"
                  disabled={!!isView}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Typography.Title level={5}>Sorting Config</Typography.Title>
            <Row className="config-form-row" align="middle">
              <Col xs={8} sm={8} md={8} lg={9} xl={8} xxl={8}>
                <Typography.Text>Default sort option : </Typography.Text>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Select
                  value={selectedDefaultSort}
                  onChange={(value) => {
                    setSelectedDefaultSort(value);
                  }}
                  options={map(SORT_OPTIONS, (option) => option)}
                  placeholder="Select Default Sort Option"
                  disabled={!!isView}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Typography.Title level={5}>EV Config</Typography.Title>
            <Row className="config-form-row" align="middle">
              <Col xs={8} sm={8} md={8} lg={9} xl={8} xxl={8}>
                <Typography.Text>Visibility :</Typography.Text>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Select
                  value={
                    evStepVisibility ||
                    VISIBILITY_OPTIONS?.DO_NOT_DISPLAY?.value
                  }
                  onChange={(value) => {
                    setEvStepVisibility(value);
                  }}
                  options={map(VISIBILITY_OPTIONS, (item) => item)}
                  placeholder="Select Visibility"
                  disabled={!!isView}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Typography.Title level={5}>Theme Config</Typography.Title>
        {themeConfig &&
          themeConfig?.map((item, index) => (
            <Row
              align="middle"
              key={`${item?.title}`}
              className="config-form-row"
            >
              <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
                {item?.title} :
              </Col>
              <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
                <Row gutter={[20, 12]} align="middle">
                  {item?.colors?.map((color, colorIndex) => (
                    <Col
                      key={`${color?.name}`}
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={6}
                      xxl={6}
                    >
                      <ColorPicker
                        format="hex"
                        showText={() => color?.title}
                        disabled={!!isView}
                        value={color?.value}
                        onChangeComplete={(value) => {
                          handleColorPick(value, index, colorIndex);
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          ))}
        <Typography.Title level={5}>Button Config</Typography.Title>
        {buttonsConfig &&
          buttonsConfig?.map((item, index) => (
            <Row
              align="middle"
              key={`${item?.title}`}
              className="config-form-row"
            >
              <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
                {item?.title} :
              </Col>
              <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
                <Row gutter={[20, 12]} align="middle">
                  {item?.configs?.map((config, configIndex) => {
                    if (config?.type === CONFIG_TYPE?.COLOR) {
                      return (
                        <Col
                          key={`${config?.name}`}
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={6}
                          xxl={6}
                        >
                          <ColorPicker
                            format={config?.isOtherColor ? colorFormat : 'hex'}
                            defaultFormat="hex"
                            showText={() => config?.title}
                            disabled={!!isView}
                            value={config?.value}
                            onChangeComplete={(value) =>
                              handleBtnConfig(
                                value,
                                index,
                                configIndex,
                                config?.type,
                                config?.isOtherColor,
                              )
                            }
                            onFormatChange={(format) => {
                              setColorFormat(format);
                            }}
                            onOpenChange={() => setColorFormat('hex')}
                          />
                        </Col>
                      );
                    }
                    return (
                      <Col
                        key={`${config?.name}`}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={6}
                        xxl={6}
                      >
                        <span>{config?.title}:</span>
                        <NumberComponent
                          isFractional={false}
                          disabled={!!isView}
                          value={config?.value}
                          type={NUMBER_FIELD_TYPES?.BORDER_RADIUS}
                          onChange={(e) =>
                            handleBtnConfig(
                              e?.target?.value,
                              index,
                              configIndex,
                              config?.type,
                            )
                          }
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          ))}
        <Typography.Title level={5}>Layout Config</Typography.Title>
        {layoutConfig &&
          layoutConfig?.map((item, index) => (
            <Row
              align="middle"
              key={`${item?.title}`}
              className="config-form-row"
            >
              <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
                {item?.title} :
              </Col>
              <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
                <Row gutter={[20, 12]} align="middle">
                  {item?.configs?.map((config, configIndex) => {
                    if (config?.type === CONFIG_TYPE?.COLOR) {
                      return (
                        <Col
                          key={`${config?.name}`}
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={6}
                          xxl={6}
                        >
                          <ColorPicker
                            format={config?.isOtherColor ? colorFormat : 'hex'}
                            defaultFormat="hex"
                            showText={() => config?.title}
                            disabled={!!isView}
                            value={config?.value}
                            onChangeComplete={(value) =>
                              handleLayoutConfig(
                                value,
                                index,
                                configIndex,
                                config?.type,
                                config?.isOtherColor,
                              )
                            }
                            onFormatChange={(format) => {
                              setColorFormat(format);
                            }}
                            onOpenChange={() => setColorFormat('hex')}
                          />
                        </Col>
                      );
                    }
                    if (config?.type === CONFIG_TYPE?.SWITCH) {
                      return (
                        <Col
                          key={`${config?.name}`}
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={6}
                          xxl={6}
                        >
                          <Row className="config-form-row d-flex align-center">
                            <Typography.Text>{config?.title}</Typography.Text>
                            <Switch
                              checked={config?.value}
                              onChange={(value) =>
                                handleLayoutConfig(
                                  value,
                                  index,
                                  configIndex,
                                  config?.type,
                                )
                              }
                              className="ml-16"
                            />
                          </Row>
                        </Col>
                      );
                    }
                    return (
                      <Col
                        key={`${config?.name}`}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={6}
                        xxl={6}
                      >
                        <span>{config?.title}:</span>
                        <NumberComponent
                          isFractional={false}
                          disabled={!!isView}
                          value={config?.value}
                          type={NUMBER_FIELD_TYPES?.HEIGHT_WIDTH}
                          onChange={(e) =>
                            handleLayoutConfig(
                              e?.target?.value,
                              index,
                              configIndex,
                              config?.type,
                            )
                          }
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          ))}
        <Typography.Title level={5}>
          Send Email & Book Survey Config
        </Typography.Title>
        {bookSurveyConfig &&
          bookSurveyConfig?.map((item, index) => (
            <Row
              align="middle"
              key={`${item?.title}`}
              className="config-form-row"
            >
              <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
                {item?.title} :
              </Col>
              <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
                <Row gutter={[20, 12]} align="middle">
                  {item?.configs?.map((config, configIndex) => {
                    if (config?.type === CONFIG_TYPE?.COLOR) {
                      return (
                        <Col
                          key={`${config?.name}`}
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={6}
                          xxl={6}
                        >
                          <ColorPicker
                            format={config?.isOtherColor ? colorFormat : 'hex'}
                            defaultFormat="hex"
                            showText={() => config?.title}
                            disabled={!!isView}
                            value={config?.value}
                            onChangeComplete={(value) =>
                              handleBookSurveyConfig(
                                value,
                                index,
                                configIndex,
                                config?.type,
                              )
                            }
                            onFormatChange={(format) => {
                              setColorFormat(format);
                            }}
                            onOpenChange={() => setColorFormat('hex')}
                          />
                        </Col>
                      );
                    }
                    if (config?.type === CONFIG_TYPE?.SWITCH) {
                      return (
                        <Col
                          key={`${config?.name}`}
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={6}
                          xxl={6}
                        >
                          <Row className="config-form-row d-flex align-center">
                            <Typography.Text>{config?.title}</Typography.Text>
                            <Switch
                              checked={config?.value}
                              onChange={(value) =>
                                handleBookSurveyConfig(
                                  value,
                                  index,
                                  configIndex,
                                  config?.type,
                                )
                              }
                              className="ml-16"
                            />
                          </Row>
                        </Col>
                      );
                    }
                    return (
                      <Col
                        key={`${config?.name}`}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={6}
                        xxl={6}
                      >
                        <span>{config?.title}:</span>
                        <NumberComponent
                          isFractional={false}
                          disabled={!!isView}
                          value={config?.value}
                          type={NUMBER_FIELD_TYPES?.HEIGHT_WIDTH}
                          onChange={(e) =>
                            handleBookSurveyConfig(
                              e?.target?.value,
                              index,
                              configIndex,
                              config?.type,
                            )
                          }
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          ))}
        <Typography.Title level={5}>Join Wait List Config</Typography.Title>
        {waitListConfig &&
          waitListConfig?.map((item, index) => (
            <Row
              align="middle"
              key={`${item?.title}`}
              className="config-form-row"
            >
              <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
                {item?.title} :
              </Col>
              <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
                <Row gutter={[20, 12]} align="middle">
                  {item?.configs?.map((config, configIndex) => {
                    if (config?.type === CONFIG_TYPE?.COLOR) {
                      return (
                        <Col
                          key={`${config?.name}`}
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={6}
                          xxl={6}
                        >
                          <ColorPicker
                            format="hex"
                            showText={() => config?.title}
                            disabled={!!isView}
                            value={config?.value}
                            onChangeComplete={(value) =>
                              handleWaitListConfig(
                                value,
                                index,
                                configIndex,
                                config?.type,
                              )
                            }
                          />
                        </Col>
                      );
                    }
                    if (config?.type === CONFIG_TYPE?.INPUT_TEXT) {
                      return (
                        <Col
                          key={`${config?.name}`}
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={6}
                          xxl={6}
                        >
                          <span>{config?.title}:</span>
                          <Input
                            disabled={!!isView}
                            value={config?.value}
                            placeHolder={config?.placeHolder}
                            onChange={(e) =>
                              handleWaitListConfig(
                                e?.target?.value,
                                index,
                                configIndex,
                                config?.type,
                              )
                            }
                          />
                        </Col>
                      );
                    }
                    if (config?.type === CONFIG_TYPE?.SELECT) {
                      return (
                        <Col
                          key={`${config?.name}`}
                          xs={12}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={6}
                          xxl={6}
                        >
                          <div>{config?.title}:</div>
                          <Select
                            disabled={!!isView}
                            value={config?.value}
                            onChange={(value) =>
                              handleWaitListConfig(
                                value,
                                index,
                                configIndex,
                                config?.type,
                              )
                            }
                            options={config?.options}
                            placeholder={config?.placeHolder}
                          />
                        </Col>
                      );
                    }
                    return (
                      <Col
                        key={`${config?.name}`}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={6}
                        xxl={6}
                      >
                        <span>{config?.title}:</span>
                        <NumberComponent
                          isFractional={false}
                          disabled={!!isView}
                          value={config?.value}
                          placeHolder={config?.placeHolder}
                          type={NUMBER_FIELD_TYPES?.BORDER_RADIUS}
                          onChange={(e) =>
                            handleWaitListConfig(
                              e?.target?.value,
                              index,
                              configIndex,
                              config?.type,
                            )
                          }
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          ))}
        <Typography.Title level={5}>
          EPC Flow Visibility Configs
        </Typography.Title>
        {visibilityConfig &&
          visibilityConfig?.map((item, index) => (
            <Row
              align="middle"
              key={`${item?.title}`}
              className="config-form-row"
            >
              <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
                {item?.title} :
              </Col>
              <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
                <Row gutter={[20, 12]} align="middle">
                  {item?.configs?.map((config, configIndex) => (
                    <Col key={`${config?.name}`} xs={12} sm={12} md={8} lg={8}>
                      <div>{config?.title}:</div>
                      <Select
                        disabled={!!isView}
                        value={config?.value}
                        onChange={(value) =>
                          handleVisibilityConfig(value, index, configIndex)
                        }
                        options={config?.options}
                        placeholder={config?.placeHolder}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          ))}
        <Typography.Title level={5}> Tooltip Icon Type </Typography.Title>
        {tooltipIconConfig &&
          tooltipIconConfig?.map((item, index) => (
            <Row
              align="middle"
              key={`${item?.title}`}
              className="config-form-row"
            >
              <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
                {item?.title} :
              </Col>
              <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
                <Row gutter={[20, 12]} align="middle">
                  {item?.configs?.map((config, configIndex) => (
                    <Col key={`${config?.name}`} xs={12} sm={12} md={8} lg={8}>
                      <div>{config?.title}:</div>
                      <Select
                        disabled={!!isView}
                        value={config?.value}
                        onChange={(value) =>
                          handleUpdateTooltipIconConfig(
                            value,
                            index,
                            configIndex,
                          )
                        }
                        options={config?.options}
                        placeholder={config?.placeHolder}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          ))}

        <Typography.Title level={5}> Add to Plan Icon Type </Typography.Title>
        {planIconConfig &&
          planIconConfig?.map((item, index) => (
            <Row
              align="middle"
              key={`${item?.title}`}
              className="config-form-row"
            >
              <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
                {item?.title} :
              </Col>
              <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
                <Row gutter={[20, 12]} align="middle">
                  {item?.configs?.map((config, configIndex) => (
                    <Col key={`${config?.name}`} xs={12} sm={12} md={8} lg={8}>
                      <div>{config?.title}:</div>
                      <Select
                        disabled={!!isView}
                        value={config?.value}
                        onChange={(value) =>
                          handleUpdateaddToPlanIconConfig(
                            value,
                            index,
                            configIndex,
                          )
                        }
                        options={config?.options}
                        placeholder={config?.placeHolder}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          ))}

        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Typography.Title level={5} className="mb-8">
              Google Analytics Config
            </Typography.Title>
            <Row className="config-form-row" align="middle">
              <Col xs={8} sm={8} md={8} lg={9} xl={8} xxl={8}>
                <Typography.Text>Tracking Id :</Typography.Text>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Input
                  value={trackingId}
                  onChange={(e) => setTrackingId(e?.target?.value)}
                  placeholder="Tracking Id"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Typography.Title level={5} className="mb-8">
              Empty State Config
            </Typography.Title>
            <div className="d-flex flex-wrap">
              <div className="mb-16">
                <Typography.Text>Upgrades Image Visible :</Typography.Text>
                <Switch
                  checked={
                    emptyImagesState?.find(
                      (image) =>
                        image?.key === EMPTY_STATE_IMAGES_KEYS.UPGRADES_PAGE,
                    )?.visibility
                  }
                  onChange={(value) =>
                    handleVisibilityChange(
                      EMPTY_STATE_IMAGES_KEYS.UPGRADES_PAGE,
                      value,
                    )
                  }
                  className="ml-16 mr-16"
                />
              </div>
              <div>
                <Typography.Text>
                  Recommendation Image Visible :
                </Typography.Text>
                <Switch
                  checked={
                    emptyImagesState?.find(
                      (image) =>
                        image?.key ===
                        EMPTY_STATE_IMAGES_KEYS.RECOMMENDATIONS_PAGE,
                    )?.visibility
                  }
                  onChange={(value) =>
                    handleVisibilityChange(
                      EMPTY_STATE_IMAGES_KEYS.RECOMMENDATIONS_PAGE,
                      value,
                    )
                  }
                  className="ml-16"
                />
              </div>
            </div>
          </Col>
          <Col xs={24}>
            <Typography.Title level={5} className="mb-8">
              Suggested Text Alignment Config
            </Typography.Title>
            <div className="d-flex flex-wrap">
              <div className="mb-16">
                <Typography.Text>
                  Align text to center (Mobile) :
                </Typography.Text>
                <Switch
                  checked={suggestedUpgradeTextCenterAlign}
                  onChange={(value) =>
                    setSuggestedUpgradeTextCenterAlign(value)
                  }
                  className="ml-16 mr-16"
                />
              </div>
            </div>
          </Col>
          <Col xs={24}>
            <Typography.Title level={5} className="mb-8">
              Header Component Config
            </Typography.Title>
            <Row>
              <Form.Item
                label="Header Component Variants"
                name="headerComponentVariants"
                initialValue={HEADER_COMPONENT_VARIENTS?.VARIANT_1?.value}
              >
                <SelectComponent
                  placeholder="Select Variant"
                  options={map(HEADER_COMPONENT_VARIENTS, (item) => item)}
                />
              </Form.Item>
              {headerComponentVariants ===
                HEADER_COMPONENT_VARIENTS?.VARIANT_2?.value && (
                <>
                  <Col sm={24}>
                    <Form.Item
                      name="headerBg"
                      label="Header Component Background"
                    >
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        maxCount={1}
                        fileList={headerPic}
                        beforeUpload={() => false}
                        onPreview={handlePreview}
                        onChange={handleChangeHeader}
                        className="avatar-uploader"
                        showUploadList={{
                          showDownloadIcon: false,
                          showPreviewIcon: true,
                          showRemoveIcon: true,
                        }}
                      >
                        {headerPic?.length === 0 && <PlusOutlined />}
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col sm={24}>
                    {headerComponentVariantConfig?.map((item, index) => (
                      <Row key={`${item?.title}`}>
                        <Col xs={8} sm={8} md={6} lg={4} xl={4} xxl={4}>
                          {item?.title} :
                        </Col>
                        <Col xs={16} sm={16} md={18} lg={20} xl={20} xxl={20}>
                          <Row gutter={[20, 12]} align="middle">
                            {item?.config?.map((color, colorIndex) => (
                              <Col
                                key={`${color?.name}`}
                                xs={12}
                                sm={12}
                                md={8}
                                lg={8}
                                xl={6}
                                xxl={6}
                              >
                                <ColorPicker
                                  format="hex"
                                  showText={() => color?.title}
                                  disabled={!!isView}
                                  value={color?.value}
                                  onChangeComplete={(value) => {
                                    handleVariantHeaderConfig(
                                      value,
                                      index,
                                      colorIndex,
                                    );
                                  }}
                                />
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Col xs={24}>
            <Typography.Title level={5} className="mb-8">
              Email Capture Config
            </Typography.Title>
            <Row>
              <Form.Item
                label="Email Capture Component Variants"
                name="emailCaptureComponentVariants"
                initialValue={EMAIL_COMPONENT_VARIENTS?.VARIANT_1?.value}
              >
                <SelectComponent
                  placeholder="Select Variant"
                  options={map(EMAIL_COMPONENT_VARIENTS, (item) => item)}
                />
              </Form.Item>
              {emailCaptureComponentVariants ===
                HEADER_COMPONENT_VARIENTS?.VARIANT_2?.value && (
                <Col sm={24}>
                  <Form.Item
                    name="emailCaptureBg"
                    label="Email Capture Background"
                  >
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      maxCount={1}
                      fileList={emailCapturePic}
                      beforeUpload={() => false}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      className="avatar-uploader"
                      showUploadList={{
                        showDownloadIcon: false,
                        showPreviewIcon: true,
                        showRemoveIcon: true,
                      }}
                    >
                      {emailCapturePic?.length === 0 && <PlusOutlined />}
                    </Upload>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default OrganizationConfigForm;
