import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import {
  ADD_TO_PLAN_BUTTON_ICON_CONFIG,
  BOOK_SURVEY_CONFIG,
  BUTTON_CONFIG,
  EMPTY_STATE_IMAGES_KEYS,
  FLOW_VISIBILITY_CONFIG,
  FORM_STEPS,
  IMAGE_UPLOAD_TYPES,
  LAYOUT_CONFIG,
  ROUTES,
  TABS,
  THEME_CONFIG,
  TOOLTIP_ICON_CONFIG,
  VARIANT_2_HEADER_CONFIG,
  WAIT_LIST_CONFIG,
} from '@common/constants';
import CommonHeader from '@components/CommonHeader';
import { AppContext } from '@context';

import { fileUpload, openNotification } from '../../../common/utils';
import history from '../../../historyData';
import OrganizationConfigForm from '../components/OrganizationConfigForm';
import OrganizationTab from '../components/OrganizationTab';
import {
  CREATE_ORGANIZATION_CONFIG,
  UPDATE_ORGANIZATION_CONFIG,
} from '../graphql/Mutations';
import {
  GET_COMPANY_CONFIG_IMAGE_UPLOAD_URL,
  GET_ORGANIZATION_CONFIG,
} from '../graphql/Queries';

const OrganizationConfiguration = () => {
  const { orgId, orgMode } = useParams();

  const [form] = Form?.useForm();
  const { dispatch } = useContext(AppContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [themeConfig, setThemeConfig] = useState(THEME_CONFIG);
  const [buttonsConfig, setButtonsConfig] = useState(BUTTON_CONFIG);
  const [waitListConfig, setWaitListConfig] = useState(WAIT_LIST_CONFIG);
  const [visibilityConfig, setVisibilityConfig] = useState(
    FLOW_VISIBILITY_CONFIG,
  );
  const [tooltipIconConfig, setTootipIconCofig] = useState(TOOLTIP_ICON_CONFIG);
  const [planIconConfig, setPlanIconCofig] = useState(
    ADD_TO_PLAN_BUTTON_ICON_CONFIG,
  );

  const [layoutConfig, setLayoutConfig] = useState(LAYOUT_CONFIG);
  const [bookSurveyConfig, setBookSurveyConfig] = useState(BOOK_SURVEY_CONFIG);
  const [
    headerComponentVariantConfig,
    setHeaderComponentVariantConfig,
  ] = useState(VARIANT_2_HEADER_CONFIG);
  const [selectedFont, setSelectedFont] = useState(null);
  const [selectedSecondaryFont, setSelectedSecondaryFont] = useState(null);
  const [selectedDefaultSort, setSelectedDefaultSort] = useState(null);
  const [selectedEmailPlace, setSelectedEmailPlace] = useState(0);
  const [isView, setIsView] = useState(false);
  const [resetData, setResetData] = useState(false);
  const [trackingId, setTrackingId] = useState(null);
  const [emptyImagesState, setEmptyImagesState] = useState([
    {
      key: EMPTY_STATE_IMAGES_KEYS.RECOMMENDATIONS_PAGE,
      visibility: true,
    },
    {
      key: EMPTY_STATE_IMAGES_KEYS.UPGRADES_PAGE,
      visibility: true,
    },
  ]);
  const [
    suggestedUpgradeTextCenterAlign,
    setSuggestedUpgradeTextCenterAlign,
  ] = useState(false);
  const [evStepVisibility, setEvStepVisibility] = useState(false);
  const [emailCapturePic, setEmailCapturePic] = useState([]);
  const [headerPic, setHeaderPic] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [createOrganizationConfig, { loading: createLoading }] = useMutation(
    CREATE_ORGANIZATION_CONFIG,
    {
      onError: () => {},
    },
  );

  const [getCompanyConfigImageUploadUrl] = useLazyQuery(
    GET_COMPANY_CONFIG_IMAGE_UPLOAD_URL,
    {
      onError: () => {},
    },
  );

  const [
    updateOrganizationConfig,
    { loading: updateLoading },
  ] = useMutation(UPDATE_ORGANIZATION_CONFIG, { onError: () => {} });

  const [
    getOrganizationConfig,
    { data: organizationConfigData },
  ] = useLazyQuery(GET_ORGANIZATION_CONFIG, {
    onError: () => {},
  });

  const handleShowPrompt = () => {
    setIsDisabled(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true,
    });
  };

  const getOrganizationConfigDetail = async () => {
    try {
      await getOrganizationConfig({
        variables: { where: { companyId: orgId } },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (orgId) {
      getOrganizationConfigDetail();
    }
    if (orgMode === 'view') {
      setIsView(true);
    }
  }, []);

  const onEdit = () => {
    setIsView(false);
    history?.push(
      `${ROUTES?.ORGANIZATIONS}/edit/${orgId}/${TABS?.ORGANIZATION_CONFIGURATION?.key}`,
    );
  };

  const onCancelEdit = (redirectFromEdit) => {
    setIsView(true);
    if (!redirectFromEdit) {
      history?.push(`${ROUTES?.ORGANIZATIONS}`);
    }
  };

  const onFinish = async (values) => {
    setIsSubmitLoading(true);
    let images = {};
    const photoKeys = {
      emailCaptureImage: '',
      headerImage: '',
    };
    const uuid = uuidv4();

    const uploadImage = async (imageType, imageValue, imageList, keyName) => {
      if (
        imageValue &&
        typeof imageValue === 'object' &&
        !imageValue.url &&
        imageList.length > 0
      ) {
        const { name } = imageValue[0];
        const ext = name.substring(name.lastIndexOf('.') + 1);
        const timestamp = Date.now();
        const filename = name.split('.').slice(0, -1).join('.');
        const newFilename = `${timestamp}_${filename}.${ext}`;
        const fileKey = `company/${uuid}/${newFilename}`;

        try {
          const res = await getCompanyConfigImageUploadUrl({
            variables: {
              data: {
                fileName: fileKey,
                companyId:
                  orgId || organizationConfigData?.getCompanyConfig?.id,
                type: imageType,
              },
            },
          });

          if (res?.data?.getCompanyConfigImageUploadUrl) {
            const { signedUrl, key } = res.data.getCompanyConfigImageUploadUrl;

            try {
              await fileUpload(signedUrl, imageValue[0]?.originFileObj);
              photoKeys[keyName] = key;
            } catch (uploadError) {
              openNotification(
                'error',
                `${imageType.toUpperCase()} upload failed. Please try again.`,
              );
            }
          }
        } catch (error) {
          return error;
        }
      }
    };

    await uploadImage(
      IMAGE_UPLOAD_TYPES.EMAIL_CAPTURE_COMPONENT,
      values?.emailCaptureBg,
      emailCapturePic,
      'emailCaptureImage',
    );

    await uploadImage(
      IMAGE_UPLOAD_TYPES.HEADER_COMPONENT,
      values?.headerBg,
      headerPic,
      'headerImage',
    );

    images = { ...photoKeys };

    const themeColors = {};
    _.forEach(themeConfig, (category) => {
      _.forEach(category?.colors, (color) => {
        _.set(themeColors, color?.name, color?.value);
      });
    });

    const buttonAllConfig = {};
    _.forEach(buttonsConfig, (category) => {
      _.forEach(category?.configs, (config) => {
        _.set(buttonAllConfig, config?.name, config?.value);
      });
    });

    const layoutAllConfig = {};
    _.forEach(layoutConfig, (category) => {
      _.forEach(category?.configs, (config) => {
        _.set(layoutAllConfig, config?.name, config?.value);
      });
    });

    const bookSurveyAllConfig = {};
    _.forEach(bookSurveyConfig, (category) => {
      _.forEach(category?.configs, (config) => {
        _.set(bookSurveyAllConfig, config?.name, config?.value);
      });
    });

    const waitListAllConfig = {};
    _.forEach(waitListConfig, (category) => {
      _.forEach(category?.configs, (config) => {
        _.set(waitListAllConfig, config?.name, config?.value);
      });
    });

    const visibilityAllConfig = {};
    _.forEach(visibilityConfig, (category) => {
      _.forEach(category?.configs, (config) => {
        _.set(visibilityAllConfig, config?.name, config?.value);
      });
    });

    const updateTooltipIconConfig = {};
    _.forEach(tooltipIconConfig, (category) => {
      _.forEach(category?.configs, (config) => {
        _.set(updateTooltipIconConfig, config?.name, config?.value);
      });
    });

    const updateAddToPlanIconConfig = {};
    _.forEach(planIconConfig, (category) => {
      _.forEach(category?.configs, (config) => {
        _.set(updateAddToPlanIconConfig, config?.name, config?.value);
      });
    });

    const updateHeaderVariantConfig = {};
    _.forEach(headerComponentVariantConfig, (category) => {
      _.forEach(category?.config, (config) => {
        _.set(updateHeaderVariantConfig, config?.name, config?.value);
      });
    });

    const fonts = {
      fontFamily: selectedFont,
      secondaryFontFamily: selectedSecondaryFont,
    };

    const formSteps = [
      {
        key: 'EMAIL',
        order: selectedEmailPlace,
      },
    ];

    const analyticsConfig = { key: trackingId || null };

    const formCustomization = [
      {
        key: FORM_STEPS?.EV_CHARGING,
        visibility: evStepVisibility,
      },
    ];

    const dataToPass = {
      fonts,
      formSteps,
      themeColors,
      buttons: buttonAllConfig,
      waitList: waitListAllConfig,
      images: emptyImagesState,
      analytics: analyticsConfig,
      updateEpcCta: visibilityAllConfig,
      layout: layoutAllConfig,
      bookSurvey: bookSurveyAllConfig,
      recommendationsDefaultSort: selectedDefaultSort,
      tooltip: {
        icon: updateTooltipIconConfig,
      },
      addToPlan: {
        icons: updateAddToPlanIconConfig,
      },
      alignment: {
        suggestedUpgradeTextCenterAlign,
      },
      formCustomization,
      emailCaptureComponent: {
        ...(values?.emailCaptureBg && {
          backgroundImage: images?.emailCaptureImage || '',
        }),
        variant: values?.emailCaptureComponentVariants,
      },
      headerComponent: {
        ...(values?.headerBg && {
          backgroundImage: images?.headerImage || '',
        }),
        variant: values?.headerComponentVariants,
        config: updateHeaderVariantConfig,
      },
    };

    if (organizationConfigData?.getCompanyConfig) {
      try {
        await updateOrganizationConfig({
          variables: {
            where: { id: organizationConfigData?.getCompanyConfig?.id },
            data: {
              ...dataToPass,
            },
          },
          onCompleted: () => onCancelEdit(true),
        });
      } catch (error) {
        setIsSubmitLoading(false);
        return error;
      }
    } else {
      if (!trackingId) {
        delete dataToPass.analytics;
      }
      try {
        await createOrganizationConfig({
          variables: {
            data: { ...dataToPass, companyId: orgId },
          },
          onCompleted: () => onCancelEdit(),
        });
      } catch (error) {
        setIsSubmitLoading(false);
        return error;
      }
    }
    setIsSubmitLoading(false);
  };

  return (
    <>
      <CommonHeader
        heading={
          organizationConfigData?.getCompanyConfig
            ? `${orgMode} Organisation Configuration`
            : 'Add Organisation Configuration'
        }
        customLink={ROUTES?.ORGANIZATIONS}
      >
        <div className="header-btn-wrapper">
          {isView ? (
            <Button className="common-button" size="middle" onClick={onEdit}>
              Edit
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                className="common-button mr-16"
                size="middle"
                onClick={() => {
                  setResetData(true);
                  setIsView(true);
                }}
              >
                Cancel
              </Button>
              <Button
                className="common-button"
                size="middle"
                onClick={form?.submit}
                loading={createLoading || updateLoading || isSubmitLoading}
                disabled={
                  createLoading ||
                  updateLoading ||
                  isDisabled ||
                  isSubmitLoading
                }
              >
                Save
              </Button>
            </>
          )}
        </div>
      </CommonHeader>
      <OrganizationTab>
        <OrganizationConfigForm
          form={form}
          onFinish={onFinish}
          handleShowPrompt={handleShowPrompt}
          organizationConfigData={organizationConfigData?.getCompanyConfig}
          themeConfig={themeConfig}
          buttonsConfig={buttonsConfig}
          setButtonsConfig={setButtonsConfig}
          layoutConfig={layoutConfig}
          setLayoutConfig={setLayoutConfig}
          bookSurveyConfig={bookSurveyConfig}
          setBookSurveyConfig={setBookSurveyConfig}
          headerComponentVariantConfig={headerComponentVariantConfig}
          setHeaderComponentVariantConfig={setHeaderComponentVariantConfig}
          setThemeConfig={setThemeConfig}
          waitListConfig={waitListConfig}
          setWaitListConfig={setWaitListConfig}
          visibilityConfig={visibilityConfig}
          setVisibilityConfig={setVisibilityConfig}
          tooltipIconConfig={tooltipIconConfig}
          setTootipIconCofig={setTootipIconCofig}
          planIconConfig={planIconConfig}
          setPlanIconCofig={setPlanIconCofig}
          selectedFont={selectedFont}
          setSelectedFont={setSelectedFont}
          selectedSecondaryFont={selectedSecondaryFont}
          setSelectedSecondaryFont={setSelectedSecondaryFont}
          selectedEmailPlace={selectedEmailPlace}
          setSelectedEmailPlace={setSelectedEmailPlace}
          isView={isView}
          trackingId={trackingId}
          setTrackingId={setTrackingId}
          emptyImagesState={emptyImagesState}
          setEmptyImagesState={setEmptyImagesState}
          selectedDefaultSort={selectedDefaultSort}
          setSelectedDefaultSort={setSelectedDefaultSort}
          suggestedUpgradeTextCenterAlign={suggestedUpgradeTextCenterAlign}
          setSuggestedUpgradeTextCenterAlign={
            setSuggestedUpgradeTextCenterAlign
          }
          resetData={resetData}
          setResetData={setResetData}
          evStepVisibility={evStepVisibility}
          setEvStepVisibility={setEvStepVisibility}
          emailCapturePic={emailCapturePic}
          setEmailCapturePic={setEmailCapturePic}
          headerPic={headerPic}
          setHeaderPic={setHeaderPic}
        />
      </OrganizationTab>
    </>
  );
};

export default OrganizationConfiguration;
